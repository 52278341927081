@import '../variables';

.modal {
  &.welcome {
    @media (max-width: 991px) {
      .modal-content {
        background: $white;
      }
    }
    @media (min-width: 992px) {
      .modal-lg, .modal-xl {
        max-width: 900px;
      }
    }
  }
  &.fade {
    .modal-dialog {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .modal-dialog {
    .modal-content {
      border-radius: 24px;
      border: none;

      .modal-header {
        border-bottom: none;

        .close {
          font-size: 38px;
          font-weight: 200;
          background: $light;
          text-shadow: none;
          padding: 2px 5px 0px 5px;
          border-radius: 32px;
          margin-top: 10px;
          margin-right: 10px;
          color: $dark;
          line-height: 28px;

          span {
            opacity: 0.7;
          }
        }
      }
      .modal-body {
        .btn-primary {
          margin: 0;
        }
      }
    }
  }
}
