@import '../theme/variables';
div[data-test='authenticator-error'] {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 20px 30px;
  color: #fff;
  background-image: $warning-gradient;
  font-weight: 800;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}
.login-background {
  min-height: 100vh;
  min-width: 100vw;
  .card {
    max-width: 475px;
    width: 100%;
    a {
      text-decoration: underline;
      &:hover {
        color: $primary-hover;
      }
    }
    nav a {
      text-decoration: none !important;
    }
    hr {
      border-top-color: $light;
    }
    .signup-text {
      font-weight: bold;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
    .terms {
      font-size: 12px;

      p {
        font-size: 12px;
      }

      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline !important;
      }
    }
    .not-found-text {
      font-size: 12px;
      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline !important;
      }
    }
    .verification-input-container {
      display: flex;
      flex-direction: column;

      .verification-input-characters {
        width: 100%;
        height: 60px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 11px;
      }
      .verification-input-character {
        line-height: 60px;
        font-size: 50px;
        border: 1px solid #afb6b9;
        color: $light;
        text-align: center;
        border-radius: 11px;
        color: #0c1f28;

        &.verification-input-character--inactive {
          color: #afb6b9;
        }
      }
      .verification-input-character--inactive {
        background-color: #f5f5f5;
        box-shadow: none;
      }
      .verification-input-character--selected {
        border-color: $primary;
        outline: $primary 2px;
        background-color: white;

        &::before {
          color: $black;
          content: '|';
          animation-name: blink;
          animation-duration: 500ms;
          animation-iteration-count: infinite;
          opacity: 1;
        }

        @keyframes blink {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      }
    }
    .meter-code-wrapper {
      margin-bottom: 30px;
      .verification-input-container {
        .verification-input-characters {
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 11px;
          height: 95px;
        }
        .verification-input-character {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .verification-input-characters {
    height: 50px;
    .verification-input-character {
      line-height: 50px;
      font-size: 30px;
    }
  }
}

.or-panel {
  display: flex;
  align-items: center;
  justify-content: center;

  .or-text {
    font-size: 14px;
    margin: 0 18px;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid $light;
  }
}
