@import 'variables';

/**
 * Amplify overrides
 */
.amplify-sign-out button {
  border: none;
  background: none;
  font-weight: bold;
  color: $white;
  font-size: 14px;
  padding: .5rem 1rem;
}

.custom-switch {
  padding-left: 0;
}

/**
 * Bootstrap overrides
 */
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1600px;
  }
}
