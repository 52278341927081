$primary: #ff0085;
$dark: #0c1f28;
$light: #afb6b9;

$white: #ffffff;
$black: #000000;
$off-white: #f5f5f5;

$primary-hover: #d1006d;

$error: #ff5a35;
$success: #3Cba92;

$primary-gradient: linear-gradient(260deg, #822a9f, #3c41ae 100%);
$secondary-gradient: linear-gradient(260deg, #bc1793, #772da1 100%);
$success-gradient: linear-gradient(80deg, #0ba360, #3cba92 100%);
$danger-gradient: linear-gradient(260deg, #ff6a44, #ff2b69 100%);
$warning-gradient: linear-gradient(260deg, #ff594f, #e5098b 100%);;
$info-gradient: linear-gradient(260deg, #6433a5, #2747b1 100%);
$app-gradient: linear-gradient(
    80deg,
    rgba(255,255,255,1) 0%,
    rgba(255,255,255,1) 50%,
    rgba(245,245,245,1) 50%,
    rgba(245,245,245,1) 100%
);
$electricity-gradient: linear-gradient(80deg, #e5098b, #ff594f 100%);
$heating-gradient: linear-gradient(80deg, #0259ab, #19d5ff 100%);
$car-gradient: linear-gradient(80deg, #629c10, #74b816 100%);
$solar-gradient: linear-gradient(80deg, #f8af1f, #f7d22d 100%);
