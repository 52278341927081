@import 'variables';

.color-primary {
  color: $primary;
}
.color-dark {
  color: $dark;
}
.color-light {
  color: $light;
}
.color-white {
  color: $white;
}
.color-black {
  color: $black;
}
.color-off-white {
  color: $off-white;
}
.color-primary-hover {
  color: $primary-hover;
}
.color-error {
  color: $error;
}
.color-success {
  color: $success;
}

.background-color-primary {
  background-color: $primary;
}
.background-color-dark {
  background-color: $dark;
}
.background-color-light {
  background-color: $light;
}
.background-color-white {
  background-color: $white;
}
.background-color-black {
  background-color: $black;
}
.background-color-off-white {
  background-color: $off-white;
}
.background-color-primary-hover {
  background-color: $primary-hover;
}
.background-color-error {
  background-color: $error;
}
.background-color-success {
  background-color: $success;
}

.border-color-primary {
  border-color: $primary;
}
.border-color-dark {
  border-color: $dark;
}
.border-color-light {
  border-color: $light;
}
.border-color-white {
  border-color: $white;
}
.border-color-black {
  border-color: $black;
}
.border-color-off-white {
  border-color: $off-white;
}
.border-color-primary-hover {
  border-color: $primary-hover;
}
.border-color-error {
  border-color: $error;
}
.border-color-success {
  border-color: $success;
}

.background-primary-gradient {
  background: $primary-gradient;
}
.background-secondary-gradient {
  background: $secondary-gradient;
}
.background-success-gradient {
  background: $success-gradient;
}
.background-danger-gradient {
  background: $danger-gradient;
}
.background-warning-gradient {
  background: $warning-gradient;
}
.background-info-gradient {
  background: $info-gradient;
}
.background-app-gradient {
  background: $app-gradient;
}
.background-electricity-gradient {
  background: $electricity-gradient;
}
.background-heating-gradient {
  background: $heating-gradient;
}
.background-car-gradient {
  background: $car-gradient;
}
.background-solar-gradient {
  background: $solar-gradient;
}
