.card {
  border-radius: 1rem;

  @media (max-width: 767px) {
    padding: 28px 32px !important;
  }

  @media (max-width: 575px) {
    padding: 24px 15px !important;
  }
}

.standard-layout .card {
  border-radius: 0.25rem;
  .card-body {
    padding: 0.8rem 2.5rem 0.8rem 0.8rem;
  }
}

.onboarding {
  .card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }
}
