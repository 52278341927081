@import '../variables';

#portal {
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.app-cta-space {
  height: 300px;
  color: $white;
  font-size: 40px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .app-cta-space {
    height: 250px !important;
  }
  .appstore-footer {
    .col {
      font-size: 32px;
    }
    img {
      width: 100%;
      max-width: 240px;
    }
  }
}

@media (max-width: 575px) {
  .app-cta-space {
    height: 250px !important;
  }
  .appstore-footer {
    .col {
      font-size: 26px;
    }
  }
}

.navbar-toggle {
  border: none;
  background: transparent !important;

  &:hover {
    background: transparent !important;
  }
  .icon-bar {
    width: 22px;
    transition: all 0.2s;
    display: block;
    height: 2px;
    border-radius: 1px;
    background-color: $white;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
    margin-top: 4px;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    margin-top: 4px;
  }
}

.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}
