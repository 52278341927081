@import '../variables';

.icon-button {
  text-align: left;
  padding: 17px 20px;
  border-radius: 37.5px;
  border: solid 1px $light;
  background: none;
  color: $dark;
  width: 100%;
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: bold;
  text-wrap: none;

  &:hover, &.active {
    background: none;
    border-color: $primary;
    color: $primary;

    svg {
      path {
        fill: $primary,
      }
      use {
        fill: $primary,
      }
    }
  }

  &.active {
    border-width: 2px;
  }
}
