@import '../variables';

/* Navbar */
.navbar {
  padding: 1rem 1rem 0.5rem 1rem !important;

  @media (min-width: 576px) {
    padding: 1rem calc(1rem + 15px) !important;
  }

  @media (min-width: 768px) {
    padding: 1.5rem calc(1rem + 15px) !important;
  }

  .nav-link {
    text-decoration: none;
    color: $white !important;
    font-weight: bold;
    font-size: 14px;

    &.active,
    &:hover {
      color: $primary !important;
    }
  }

  .onboarding-nav-container & .nav-link:hover {
    color: white !important;
  }
}
