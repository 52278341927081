@import '../variables';

.btn {
  border-radius: 22.5px;
  font-weight: bold;
  padding: 0.5rem;
  text-decoration: none;

  @media (max-width: 575px) {
    font-size: 14px;
  }

  &.disabled {
    cursor: not-allowed;
  }
  &.btn.focus,
  &.btn:focus {
    box-shadow: none;
  }
}
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  margin: 30px 0 25px;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  &.btn-primary.disabled,
  &.btn-primary:disabled {
    background-color: $primary-hover;
    border-color: $primary-hover;
    opacity: 0.6;
  }
}
.btn-outline-primary {
  border-color: $primary;
  color: $primary;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border-color: $primary-hover;
    background-color: $white;
    color: $primary-hover;
  }
  &.btn-primary.disabled,
  &.btn-primary:disabled {
    border-color: $primary-hover;
    background-color: $white;
    color: $primary-hover;
    opacity: 0.6;
  }
}
.btn-secondary {
  background: $white;
  color: $primary;
  border: 2px solid $primary;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  .btn-primary.disabled,
  .btn-primary:disabled {
    background: $white !important;
    border-color: $primary-hover !important;
    color: $primary-hover !important;
  }
}
.btn-light {
  background: none;
  color: $light;
  text-decoration: none;
  border: 2px solid $off-white;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  .btn-primary.disabled,
  .btn-primary:disabled {
    background: none !important;
    border: 2px solid $off-white;
    color: $dark !important;
  }
}
.btn-link {
  background: none;
  border-radius: 0;
  font-weight: 400;
  text-decoration: underline;
  color: $primary;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: $primary-hover;
  }
}

.btn-inline-link {
  @extend .btn-link;
  padding: 0;
  vertical-align: baseline;
  font-weight: 400;
}
