@import '_variables.scss';

/* Typography */
body {
  font-family: 'Poppins';
  font-weight: normal;
}

p {
  @media (max-width: 575px) {
    font-size: 14px;
  }
}

h1 {
  color: $dark;
  font-weight: 700;
  font-size: 26px;

  // This is so that we can let the desktop align to
  // whatever the default is for each individual element
  @media (max-width: 575px) {
    text-align: center;
  }

  @media (min-width: 576px) {
    font-size: 32px;
  }

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

h2 {
  font-weight: 700;
  color: #ff0085;
  font-size: 22px;

  @media (min-width: 576px) {
    font-size: 26px;
  }

  @media (min-width: 768px) {
    font-size: 30px;
  }
}

h3 {
  font-weight: 800;
  color: $dark;
  font-size: 20px;

  @media (min-width: 576px) {
    font-size: 20px;
  }

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

h4 {
  font-weight: normal;
  color: $dark;
  font-size: 16px;
}

h5 {
  font-size: 16px;
  font-weight: 800;
  color: $dark;
}

a {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary-hover;
  }
}
