@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'variables';

.light {
  font-family: 'Poppins';
  font-weight: 200;
};
.semilight {
  font-family: 'Poppins';
  font-weight: 300;
};
.book {
  font-family: 'Poppins';
  font-weight: 400;
};
.normal {
  font-family: 'Poppins';
  font-weight: normal;
};
.medium {
  font-family: 'Poppins';
  font-weight: 500;
};
.demi {
  font-family: 'Poppins';
  font-weight: 600;
};
.semibold {
  font-family: 'Poppins';
  font-weight: 700;
};
.bold {
  font-family: 'Poppins';
  font-weight: 800;
};

.sm-text {
  font-size: 12px;
}
.em-text {
  font-weight: 800;
  color: $primary;
}
