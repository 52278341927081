@import '../variables';

.alert {
  border: none;
  font-weight: 800;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);

  &.alert-primary {
    color: $white;
    background-image: $primary-gradient;
  }
  &.alert-secondary {
    color: $white;
    background-image: $secondary-gradient;
  }
  &.alert-success {
    color: $white;
    background-image: $success-gradient;
  }
  &.alert-danger {
    color: $white;
    background-image: $danger-gradient;
  }
  &.alert-warning {
    color: $white;
    background-image: $warning-gradient;
  }
  &.alert-info {
    color: $white;
    background-image: $info-gradient;
  }
  &.alert-light {
    color: $dark;
    background: $light;
  }
  &.alert-dark {
    color: $white;
    background: $dark;
  }

  .close{
    font-weight: 300;
    opacity: 1;
    font-size: 35px;
    line-height: 0.75;
    text-shadow: none;
  }
}
