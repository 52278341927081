@import '../variables';

/* Forms */
.form-label {
  font-size: 14px;
  font-weight: bold;
  color: $dark;

  @media (min-width: 576px) {
    font-size: 16px;
  }
}
.form-control {
  border: 1px solid $light;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
  &.error {
    border-color: $error;
  }
  &.is-valid {
    border-color: $success;
    background-image: url('../../assets/svg/check-mark.svg');
  }
}
select.form-control {
  background-position: center right calc(1em + 0.1875rem) !important;
}
